.attendpage {
  padding: 10px 19px;
  .userversion {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      width: 100%;
      font-size: 28px;
      margin: 15px 0 20px 4px;
    }
    .attendstate {
      width: 170px;
      height: 170px;
      margin: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 30px;
      border-radius: 100px;

      .innercircle {
        width: 158px;
        height: 158px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid white;
        border-radius: 100px;

        svg {
          margin: 28px 10px 10px 10px;
          font-size: 40px;
        }
      }
    }

    .stat {
      width: 100%;
      height: 100px;
      border: 1px solid red;

      h4 {
        background-color: rgb(244, 247, 250);
        font-weight: 500;
        text-align: center;
        font-size: 15px;
        height: 25px;
      }

      .counts {
        display: flex;

        div {
          border: 1px solid black;
          width: 33.333%;
          height: 75px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }

    form {
      width: 322px;
      height: auto;
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .inputs {
        display: flex;

        input {
          width: 78.5px;
          height: 78.5px;
          border-radius: 5px;
          border: 2px solid rgb(161, 161, 161);
          margin: 1px;
          font-size: 65px;
          text-align: center;
        }
      }

      .attendbtn {
        width: 250px;
        height: 54px;
        margin-top: 30px;
        margin-bottom: 20px;
        background-color: #000080;
        opacity: 0.5;
        color: white;
        border: none;
        border-radius: 5px;
        font-size: 19px;
        font-weight: 500;
      }
    }
  }

  .adminversion {
    display: none;
  }
}

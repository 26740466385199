.colortxtbox {
  width: 300px;
  height: 300px;
  margin: 0 12px;
  padding: 20px;
  background-color: rgb(244, 247, 250);
  border-radius: 20px;

  .title {
    font-size: 22px;
  }

  h4 {
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 10px;
  }
}

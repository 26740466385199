.home {
  width: 100vw;
  min-height: auto;
  display: flex;
  flex-direction: column;
  // position: relative;
  overflow-x: hidden;
}

@media all and (min-width: 769px) {
  .home {
    display: none;
  }
}

// @import url("https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,100..700;1,100..700&display=swap");

.mainimg {
  width: 100vw;
  // height: 53.7vw;
  height: 46.8vw;
  // height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  position: relative;

  // .mainimg_wide {
  //   display: flex;
  //   justify-content: center;

  //   img {
  //     width: 100vw;
  //     height: 46.8vw;
  //     // height: 53.7vw;
  //   }

  //   .black {
  //     width: 100vw;
  //     height: 46.8vw;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     position: absolute;
  //     top: 0;
  //     background-color: rgba(0, 0, 0, 0.36);

  //     .txtbox {
  //       width: 707px;
  //       height: 100%;
  //       padding-bottom: 20px;
  //       // padding-top: 580px;
  //       display: flex;
  //       flex-direction: column;
  //       justify-content: end;
  //       text-align: center;
  //       z-index: 30;
  //       font-weight: 300;
  //       white-space: nowrap;

  //       p {
  //         color: white;
  //         font-size: 75px;
  //         // margin-bottom: 10px;
  //         text-shadow: 0 0 5px black;
  //         font-family: "Josefin Slab", serif;
  //       }
  //     }
  //   }

  //   .gradient {
  //     position: absolute;
  //     bottom: -5.8vw;
  //     width: 100vw;
  //     height: 15vw;
  //     background: linear-gradient(
  //       rgba(0, 0, 0, 0) 0%,
  //       rgba(0, 0, 0, 1) 40%,
  //       rgba(0, 0, 0, 1) 100%
  //     );
  //   }
  // }

  .mainimg_small {
    img {
      width: 100vw;
    }

    .gray {
      width: 100%;
      height: 69.31vw;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(1, 1, 1, 0.4);
      position: absolute;
      top: 0;

      .txt {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h3 {
          margin: 5vw 0 8vw 0;
          color: white;
          font-size: 4.8vw;
          font-weight: 600;
        }

        a {
          width: 35vw;
          height: 13vw;
          margin-right: 11px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: none;
          color: white;
          border: 1px solid white;
          border-radius: 17px;
          font-size: 20px;
          font-weight: 600;
        }

        a:hover {
          cursor: pointer;
          background-color: rgba(1, 1, 1, 0.6);
        }
      }

      @media screen and (max-width: 1050px) {
        .txt {
          margin-left: 0;
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .mainimg {
    height: 69.31vw;

    .mainimg_wide {
      display: none;
    }

    .mainimg_small {
      display: block;

      .gray {
        .txt {
          // border: 1px solid red;
          width: 350px;
          height: 250px;

          h3 {
            margin: 40px 0 25px 0;
            font-size: 25.5px;
          }

          a {
            width: 170px;
            height: 55px;
            font-size: 19.5px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 480px) {
  .mainimg {
    .mainimg_small {
      .gray {
        .txt {
          width: 280px;
          height: 200px;

          h3 {
            margin: 34px 0 25px 0;
            font-size: 22.5px;
          }

          a {
            width: 150px;
            height: 50px;
            font-size: 18px;
          }
        }
      }
    }
  }
}

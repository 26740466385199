.home-about {
  //   height: 540px;
  background-color: rgb(240, 240, 240);
  //   border: 1px solid red;

  .imgs {
  }
}

@media all and (max-width: 768px) {
  .home-about {
    width: 100%;
    height: auto;
    padding: 10px 0px 50px 0px;

    h5 {
      font-size: 21px;
      margin-top: 40px;
      margin: 40px 14px 0 14px;
    }

    h3 {
      font-size: 33px;
      margin: 5px 14px 25px 14px;
    }

    p {
      font-size: 19px;
      line-height: 1.5;
      margin: 0 14px 20px 14px;
      //   padding: 0 2px;
    }

    .imgcards {
      //   border: 1px solid pink;
      width: 97vw;
      margin: 0 1.5vw;
      // padding: 0;
      display: flex;
      // justify-content: center;
      //   flex-direction: column;
      //   align-items: center;
      flex-wrap: wrap;
    }
  }
}

@media all and (max-width: 480px) {
  .home-about {
    h5 {
      font-size: 16px;
    }

    h3 {
      font-size: 28px;
    }

    p {
      font-size: 16.5px;
    }

    .imgcards {
      width: 97vw;
      margin: 0 1.5vw;
    }
  }
}

.search_result {
  height: calc(100vh - 321px);

  .search_result_header {
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(16, 112, 202);
    color: white;
  }

  form {
    padding: 10px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .note {
      font-size: 16px;
    }

    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid rgb(230, 230, 230);

      span {
        font-weight: 570;
        padding-left: 5px;
      }

      input {
        width: 82%;
        background-color: rgb(243, 243, 243);
        border: 2px solid rgb(230, 230, 230);
      }

      input:focus {
        outline: none;
      }
    }

    button {
      width: 100px;
      height: 42px;
      background-color: rgb(102, 118, 141);
      color: white;
      border-radius: 2px;
      border: none;
    }
  }
}

@media all and (max-width: 768px) {
  .search_result {
    .search_result_header {
      height: 60px;
      font-size: 25px;
    }

    form {
      .note {
        font-size: 17px;
      }

      div {
        height: 70px;

        span {
          font-size: 18.5px;
        }

        input {
          height: 52px;
          font-size: 18.5px;
        }
      }

      button {
        height: 44px;
        margin-top: 20px;
        font-size: 18px;
      }
    }
  }
}

@media all and (max-width: 480px) {
  .search_result {
    .search_result_header {
      height: 55px;
      font-size: 23px;
    }

    form {
      .note {
        font-size: 16px;
      }

      div {
        height: 60px;

        span {
          font-size: 17px;
        }

        input {
          height: 45px;
          font-size: 17px;
        }
      }

      button {
        height: 42px;
        margin-top: 18px;
        font-size: 16px;
      }
    }
  }
}

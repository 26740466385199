.imagecard {
  width: 45.5vw;
  height: 54.6vw;
  margin: 1.5vw;
  padding: 30px;
  position: relative;
  box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  overflow: hidden;

  img {
    width: 100%;
    height: 70%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }

  .gray {
    width: 100%;
    height: 70%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.15);
    z-index: 3;
  }

  .white {
    width: 100%;
    height: 30%;
    position: absolute;
    padding: 11px;
    left: 0;
    bottom: 0;
    background-color: white;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    h4 {
      font-size: 16px;
    }

    h5 {
      margin: 4px 0 0 0;
      color: rgb(159, 159, 159);
      font-size: 12.5px;
      font-weight: 500;
    }
  }
}

@media all and (max-width: 768px) {
  .imagecard {
    .white {
      padding: 5.5%;
      h4 {
        font-size: 20px;
      }

      h5 {
        font-size: 15px;
      }
    }
  }
}

@media all and (max-width: 630px) {
  .imagecard {
    .white {
      h4 {
        font-size: 18px;
      }

      h5 {
        font-size: 13px;
      }
    }
  }
}

@media all and (max-width: 530px) {
  .imagecard {
    .white {
      padding: 5%;
      h4 {
        font-size: 16px;
      }

      h5 {
        font-size: 11.5px;
      }
    }
  }
}

@media all and (max-width: 440px) {
  .imagecard {
    .white {
      h4 {
        font-size: 14px;
      }

      h5 {
        font-size: 10.5px;
      }
    }
  }
}

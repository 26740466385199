.recruit_result {
  .recruit_result_header {
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(16, 112, 202);
    color: white;
  }

  .result_pass {
    padding: 60px 0 15px 0;
    border-bottom: 1px solid rgb(230, 230, 230);
    display: flex;
    flex-direction: column;
    align-items: center;

    .result_pass_inner {
      width: 320px;
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        font-size: 22px;
        margin-bottom: 10px;
      }

      p {
        color: rgb(40, 40, 40);
        font-size: 18px;
        margin-bottom: 4px;
      }

      span {
        font-size: 18px;
      }

      .red {
        font-size: 19.5px;
        font-weight: 500;
        color: rgb(241, 57, 57);
        margin-bottom: 10px;
      }

      .gray {
        width: 100vw;
        padding-left: 15px;
        margin-top: 30px;
        font-size: 13.5px;
        color: gray;
        line-height: 1.2;
      }
    }
  }

  .note {
    padding: 20px 15px 15px 15px;

    h3 {
      font-size: 17.5px;
      font-weight: 700;
      margin-bottom: 9px;
      text-decoration: underline;
    }

    p {
      margin-left: 2px;
      font-size: 15px;
      margin-bottom: 20px;
    }
  }
}

@media all and (max-width: 768px) {
  .recruit_result {
    .recruit_result_header {
      height: 60px;
      font-size: 25px;
    }

    .result_pass {
      padding: 80px 0 25px 0;

      .result_pass_inner {
        width: 400px;

        h3 {
          font-size: 33px;
          margin-bottom: 24px;
        }

        p {
          font-size: 23px;
          margin-bottom: 5px;
        }

        span {
          font-size: 23px;
        }

        .red {
          font-size: 24.5px;
        }

        .gray {
          padding-left: 17px;
          margin-top: 45px;
          font-size: 16px;
        }
      }
    }

    .note {
      padding: 30px 20px 10px 20px;

      h3 {
        font-size: 22px;
        margin-bottom: 13px;
      }

      p {
        margin-left: 2px;
        font-size: 17.5px;
        margin-bottom: 35px;
      }
    }
  }
}

@media all and (max-width: 480px) {
  .recruit_result {
    .recruit_result_header {
      height: 55px;
      font-size: 23px;
    }

    .result_pass {
      padding: 60px 0 25px 0;

      .result_pass_inner {
        width: 320px;

        h3 {
          font-size: 22px;
          margin-bottom: 10px;
        }

        p {
          font-size: 18px;
          margin-bottom: 4px;
        }

        span {
          font-size: 18px;
        }

        .red {
          font-size: 19.5px;
          margin-bottom: 10px;
        }

        .gray {
          padding-left: 15px;
          margin-top: 30px;
          font-size: 13.5px;
        }
      }
    }

    .note {
      padding: 20px 15px 10px 15px;

      h3 {
        font-size: 17.5px;
        margin-bottom: 9px;
      }

      p {
        margin-left: 2px;
        font-size: 15px;
        margin-bottom: 20px;
      }
    }
  }
}

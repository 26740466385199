// * {
//   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
// }

header {
  width: 100vw;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  background-color: #fff;
  border-bottom: 1px solid lightgray;
  z-index: 999;

  .header_inner_wide {
    width: 1300px;
    height: 90px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;

    .mainlogo {
      height: 100%;
      display: flex;
      align-items: center;

      img {
        height: 62px;
        margin-top: 5px;
        margin-left: 10px;
      }
    }

    .navbar {
      display: flex;
      align-items: center;

      li {
        a {
          margin: 0 10px;
          padding: 18px;
          color: black;
          font-size: 17px;
          font-weight: bold;
        }

        a:hover {
          color: rgb(24, 50, 77);
          border-bottom: 2px solid #000080;
        }
      }
    }
  }

  .header_inner_small {
    width: 100vw;
    height: 90px;
    padding: 0 10px;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .header_inner_small_top {
      width: 100%;
      height: 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      z-index: 10;

      .mainlogo {
        height: 100%;
        display: flex;
        align-items: center;

        img {
          height: 42px;
          margin-top: 5px;
          margin-left: 10px;
        }
      }

      .navbtn {
        width: 60px;
        height: 50px;
        margin-top: 5px;
        padding: 7px 7px 7px 10px;
        font-size: 35px;
      }

      .navbtn:hover {
        cursor: pointer;
      }
    }

    .header_inner_coverwhite {
      width: 100%;
      height: 480px;
      background-color: white;
      position: absolute;
      top: -395px;
      z-index: 9;
    }

    .header_inner_small_navbar {
      width: 100%;
      height: 240px;
      padding: 0;
      background-color: white;
      transition: all 0.5s;
      position: absolute;

      ul {
        display: flex;
        flex-direction: column;
        border-top: 1px solid lightgray;

        li {
          height: 60px;
          border-bottom: 1px solid lightgray;
          font-size: 19px;

          a {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: black;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  header {
    .header_inner_wide {
      display: none;
    }

    .header_inner_small {
      display: flex;

      .header_inner_small_navbar {
        height: 181px;

        ul {
          li {
            height: 60px;

            a {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  header {
    .header_inner_small {
      .header_inner_small_navbar {
        background-color: white;
        height: 166px;
        ul {
          li {
            height: 55px;

            a {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

footer {
  width: 100vw;
  height: 230px;
  display: flex;
  justify-content: center;
  position: relative;
  background-color: #191919;
  color: #e6e6e6;

  .footer_inner_wide {
    width: 1100px;
    padding: 40px 10px 0 10px;
    display: flex;
    justify-content: space-between;

    .copyright {
      margin: 30px 0 0 20px;

      h4 {
        margin-bottom: 10px;
        font-size: 17px;
        font-weight: 400;
      }

      p {
        font-size: 13.5px;
      }
    }

    .channel {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #e6e6e6;

      h3 {
        margin-left: 5px;
        margin-bottom: 15px;
        font-size: 17px;
      }

      .sns {
        margin-bottom: 35px;

        a {
          margin: 0 4px;
          color: #e6e6e6;

          svg {
            width: 50px;
            height: 50px;
            padding: 8px;
          }

          .facebooklink {
            padding: 10px;
            margin-right: -3px;
          }

          .youtubelink {
            padding: 6px;
            margin-left: 3px;
          }

          svg:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .footer_inner_small {
    display: none;

    .channel {
      margin-top: 55px;
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        margin-bottom: 15px;
        font-size: 17px;
      }

      .sns {
        margin-bottom: 35px;

        a {
          margin: 0 4px;
          color: #e6e6e6;

          svg {
            width: 50px;
            height: 50px;
            padding: 8px;
          }

          .facebooklink {
            padding: 10px;
            margin-right: -3px;
          }

          .youtubelink {
            padding: 6px;
            margin-left: 3px;
          }

          svg:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  button {
    width: 62px;
    height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    color: rgb(63, 122, 211);
    border: 3px solid rgb(63, 122, 211);
    position: absolute;
    bottom: 20px;
    right: 20px;

    svg {
      width: 31px;
      height: 31px;
    }
  }

  button:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 580px) {
  footer {
    .footer_inner_wide {
      display: none;
    }

    .footer_inner_small {
      display: flex;
    }

    button {
      width: 58px;
      height: 58px;

      svg {
        width: 27px;
        height: 27px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  footer {
    .footer_inner_small {
      .sns {
        .links {
          a {
            svg {
              padding: 10px;
            }

            .facebooklink {
              padding: 12px;
              margin-right: -8px;
            }

            .youtubelink {
              padding: 8px;
              margin-left: -2px;
            }
          }
        }
      }
    }
  }
}

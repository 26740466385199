.signup {
  // border: 1px solid blue;
  padding-top: 30px;
  height: 1100px;
  display: flex;
  justify-content: center;
  // align-items: center;
  font-family: "Pretendard Variable", Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;

  form {
    width: 450px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    text-align: center;

    h4 {
      margin: 15px 118px 60px 118px;
      font-size: 40px;
      font-weight: 600;
    }

    // h3 {
    //   padding: 6px 0 4px 2px;
    //   text-align: start;
    //   font-size: 17px;
    // }

    // p {
    //   text-align: start;
    //   font-weight: 400;
    //   font-size: 14.5px;
    //   padding: 0 0 3px 2px;
    //   color: #808080;
    // }

    .idbox {
      display: flex;
      align-items: center;

      input {
        width: 270px;
        margin-top: 3px;
        margin-bottom: 8px;
        border-right: none;
      }

      button {
        width: 120px;
        margin-top: 3px;
        margin-bottom: 8px;
        font-size: 16.7px;
        opacity: 1;
      }
    }

    // input {
    //   height: 54px;
    //   margin-top: 3px;
    //   margin-bottom: 8px;
    //   padding: 0 10px;
    //   font-size: 16.7px;
    //   border: 2px solid rgb(224, 224, 224);
    //   border-radius: 5px;
    //   outline: none;
    // }

    input::placeholder {
      color: rgb(180, 180, 180);
      font-weight: 500;
      font-size: 16.7px;
      font-family: "Pretendard Variable", Pretendard, -apple-system,
        BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
        "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic",
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    }

    .pwchecktext {
      text-align: start;
      padding: 0 0 3px 2px;
    }

    .nametxt {
      margin-top: 30px;
    }
    .ip4 {
      margin-top: 30px;
    }

    .sizedbox {
      height: 35px;
    }
    button {
      height: 54px;
      margin-top: 15px;
      background-color: #000080;
      opacity: 0.5;
      color: white;
      border: none;
      border-radius: 5px;
      font-size: 19px;
      font-weight: 500;
      font-family: "Pretendard Variable", Pretendard, -apple-system,
        BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
        "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic",
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    }

    button:hover {
      cursor: pointer;
    }
  }
}

@media all and (max-width: 480px) {
  .signup {
    form {
      width: 330px;
      padding: 15px;
      // border: 1px solid red;

      h4 {
        margin: 0 0 40px 0;
        font-size: 29px;
      }
    }
  }
}

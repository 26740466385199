.home-recruit {
  width: 100%;
  // border: 1px solid red;
  font-family: "Pretendard Variable", Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  display: flex;
  justify-content: center;
  // align-items: center;
  overflow: hidden;

  .faq {
    height: 1400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // border: 1px solid blue;
    padding: 0 50px;

    h5 {
      width: 1200px;
      padding-left: 5px;
      margin-top: 70px;
      font-size: 19px;
    }

    h3 {
      width: 1200px;
      margin: 5px 0 40px 0;
      padding-left: 5px;
      // text-align: center;
      font-size: 55px;
    }

    ul {
      width: 1200px;
      border-top: 1px solid #e1e8ef;
    }
  }

  // .go-recruit {
  //   height: 650px;
  //   display: flex;
  //   justify-content: center;
  //   background-color: rgb(232, 232, 232);
  //   position: relative;

  //   img {
  //     width: 1920px;
  //     height: 650px;
  //   }

  //   .gray {
  //     width: 100%;
  //     height: 650px;
  //     display: flex;
  //     align-items: center;
  //     background: rgba(1, 1, 1, 0.4);
  //     position: absolute;
  //     top: 0;

  //     .txt {
  //       width: 600px;
  //       height: 350px;
  //       margin-left: calc(-320px + 30vw);
  //       display: flex;
  //       flex-direction: column;
  //       justify-content: center;
  //       align-items: center;

  //       h3 {
  //         margin: 35px 0;
  //         color: white;
  //         font-size: 44px;
  //         font-weight: 600;
  //       }

  //       a {
  //         width: 180px;
  //         height: 52px;
  //         margin-right: 11px;
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;
  //         background: none;
  //         color: white;
  //         border: 1px solid white;
  //         border-radius: 17px;
  //         font-size: 20px;
  //         font-weight: 600;
  //         font-family: "Pretendard Variable", Pretendard, -apple-system,
  //           BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
  //           "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic",
  //           "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  //       }

  //       a:hover {
  //         cursor: pointer;
  //         background-color: rgba(1, 1, 1, 0.6);
  //       }
  //     }

  //     @media screen and (max-width: 1050px) {
  //       .txt {
  //         margin-left: 0;
  //       }
  //     }
  //   }
  // }
}

@media all and (max-width: 768px) {
  .home-recruit {
    // border: 1px solid blue;
    width: 100vw;
    height: 600px;

    .faq {
      width: 100%;
      padding: 10px 9px;
      height: 1300px;

      h5 {
        width: 100%;
        font-size: 21px;
        // margin-left: 9vw;
        margin-top: 40px;
        // margin: 0;
      }

      h3 {
        width: 100%;
        font-size: 33px;
        margin-bottom: 30px;
        // margin-left: 9vw;
      }

      ul {
        width: 100%;
      }
    }
  }
}

@media all and (max-width: 480px) {
  .home-recruit {
    // border: 1px solid red;
    width: 100vw;
    height: 540px;
    // border: 1px solid red;

    .faq {
      // color: red;
      width: 100%;
      padding: 10px 9px;
      height: 540px;

      h5 {
        width: 100%;
        font-size: 16px;
        // margin-left: 9vw;
        margin-top: 40px;
        // margin: 0;
      }

      h3 {
        width: 100%;
        font-size: 28px;
        margin-bottom: 30px;
        // margin-left: 9vw;
      }

      ul {
        width: 100%;
      }
    }
  }
}

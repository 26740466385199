.recruittextbox {
  width: 100%;
  height: auto;
  padding: 40px 0px 0px 0px;

  h3 {
    font-size: 33px;
    margin: 0px 14px 30px 23px;
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 24px 24px 0 24px;
    padding: 0 15px 20px 15px;
    box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.5);

    div {
      height: auto;

      h4 {
        width: 105px;
        border-radius: 17px;
        text-align: center;
        margin-top: 20px;
        padding: 7.5px 10px;
        margin-bottom: 16px;
        font-size: 18.5px;
        background-color: rgb(16, 112, 202);
        color: white;
        font-weight: 500;
      }

      p {
        margin: 10px 0 10px 5px;
        line-height: 1.3;
        font-size: 18.5px;
      }
    }
  }
}

@media all and (max-width: 480px) {
  .recruittextbox {
    h3 {
      font-size: 28px;
      margin-bottom: 0;
    }

    .card {
      div {
        h4 {
          width: 93px;
          padding: 7px 10px;
          font-size: 16.5px;
        }

        p {
          font-size: 16.5px;
        }
      }
    }
  }
}

.qnabox {
  width: 1200px;
  height: 102px;
  // padding: 39px 85px 37px 85px;
  border-bottom: 1px solid #e1e8ef;
  transition: all 0.5s;
  // border: 1px solid red;

  .question {
    display: flex;
    align-items: center;

    .number {
      margin-right: 30px;
      font-size: 18px;
      font-weight: 700;
    }

    div {
      width: 100%;
      height: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 21px;
      font-weight: 500;

      .arrow {
        width: 50px;
        height: 50px;
        padding: 12px;
        transition: all 0.5s;
      }

      .arrow:hover {
        cursor: pointer;
      }
    }
  }

  .answer {
    height: 23px;
    margin-top: 35px;
    margin-left: 48.67px;
    font-size: 19px;
    font-weight: 400;
    transition: all 0.3s;
  }
}

@media all and (max-width: 768px) {
  .qnabox {
    width: 100%;
    height: 80px;
    padding: 20px 10px;

    .question {
      .number {
        margin-right: 12px;
        font-size: 17.5px;
      }

      div {
        height: 40px;
        // border: 1px solid red;
        font-size: 20px;

        .arrow {
          width: 40px;
          height: 40px;
          // border: 1px solid red;
          padding: 10px;
        }
      }
    }

    .answer {
      height: 23px;
      margin-top: 10px;
      margin-left: 31.3px;
      font-size: 21px;
    }
  }
}

@media all and (max-width: 480px) {
  .qnabox {
    width: 100%;
    height: 60px;
    padding: 15px 10px;

    .question {
      .number {
        margin-right: 12px;
        font-size: 12px;
      }

      div {
        height: 30px;
        font-size: 15.5px;

        .arrow {
          width: 30px;
          height: 30px;
          // border: 1px solid red;
          padding: 7px;
        }
      }
    }

    .answer {
      height: 23px;
      margin-top: 10px;
      margin-left: 24.3px;
      font-size: 15.5px;
    }
  }
}

.recruit {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .recruit_wide {
    .recruit_image {
      width: 100vw;
      height: 400px;
      // border: 1px solid red;
      margin-bottom: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgb(157, 190, 244);
      position: relative;

      img {
        // border: 1px solid green;
        width: 930px;
        height: 400px;
        margin-left: 350px;
        position: absolute;
        top: 0;
        z-index: 10;
      }

      .gray {
        width: 100vw;
        height: 400px;
        background-color: rgba(255, 255, 255, 0.2);
        position: absolute;
        z-index: 10;
        margin: 0;
      }

      .recruit_text {
        width: 930px;
        z-index: 11;

        h2 {
          width: 970px;
          margin-top: 135px;
          margin-bottom: 20px;
          text-align: center;
          font-size: 48px;
          font-weight: 600;
        }

        h3 {
          width: 970px;
          font-weight: 300;
          font-size: 20px;
        }
      }
    }
    .target {
      width: 970px;
      // border: 1px solid red;

      .targetbox {
        display: flex;

        div {
          width: 300px;
          height: 300px;
          margin: 0 12px;
          padding: 20px;
          background-color: rgb(244, 247, 250);
          border-radius: 20px;

          h3 {
            font-size: 22px;
          }

          h4 {
            font-size: 17px;
            font-weight: 400;
            margin-bottom: 10px;
          }
        }
      }
    }

    .schedule {
      .schedulebox {
        display: flex;

        div {
          width: 300px;
          height: 300px;
          margin: 0 12px;
          padding: 20px;
          background-color: rgb(244, 247, 250);
          border-radius: 20px;

          h3 {
            font-size: 22px;
          }

          h4 {
            font-size: 17px;
            font-weight: 400;
            margin-bottom: 10px;
            line-height: 140%;
          }
        }
      }
    }

    .note {
      padding-bottom: 50px;

      h4 {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 5px;
      }
    }
  }

  // media-size 769 미만

  .recruit_small {
    display: none;

    .recruit_image_small {
      width: 100vw;
      height: 69.26vw;
      margin-bottom: 10px;
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        position: absolute;
      }

      .gray {
        width: 100vw;
        height: 69.26vw;
        background-color: rgba(255, 255, 255, 0.2);
        position: absolute;
        top: 0;
        z-index: 10;
      }

      .recruit_text {
        width: 380px;
        z-index: 11;

        h2 {
          width: 100%;
          margin-top: 45px;
          margin-bottom: 16px;
          text-align: center;
          font-size: 30px;
          font-weight: 600;
        }

        h3 {
          width: 100%;
          text-align: center;
          font-weight: 300;
          font-size: 15px;
        }
      }

      .recruit_text1 {
        width: 380px;
        z-index: 11;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .result {
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        color: rgb(60, 60, 60);
        text-align: center;
      }

      a {
        padding: 0 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgb(106, 169, 238);
        color: white;
        border: none;
      }

      button:hover {
        cursor: pointer;
      }

      .recruit_btn_green {
        background-color: rgb(93, 197, 146);
      }
    }

    .recruit_content_small {
      .target {
        width: 100%;
        height: auto;
        padding: 40px 0px 0px 0px;

        h3 {
          margin: 0px 14px 0 23px;
        }
      }

      .notes {
        width: 100vw;
        padding: 40px 0 50px 0;

        h3 {
          margin: 0px 14px 24px 23px;
          font-size: 33px;
        }
        p {
          margin: 10px 29px 10px 29px;
          font-size: 18px;
          line-height: 1.2;
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .recruit {
    .recruit_wide {
      display: none;
    }

    .recruit_small {
      display: block;

      .result {
        padding-bottom: 100px;
        border-top: 8px solid rgb(16, 112, 202);
        border-bottom: 55px solid rgb(242, 242, 242);

        h2 {
          margin: 90px 0 60px 0;
          font-size: 35px;
        }

        a {
          width: 350px;
          height: 70px;
          margin-bottom: 13px;
          font-size: 18.5px;
        }
      }
    }
  }
}

@media all and (max-width: 480px) {
  .recruit {
    .recruit_small {
      .recruit_image_small {
        margin-bottom: 0;

        .recruit_text {
          h2 {
            margin-top: 25px;
            margin-bottom: 13px;
            font-size: 22px;
          }

          h3 {
            font-size: 14px;
          }
        }
      }

      .result {
        padding-bottom: 60px;
        margin-bottom: -5px;
        border-top: 6px solid rgb(16, 112, 202);
        border-bottom: 35px solid rgb(242, 242, 242);

        h2 {
          margin: 70px 0 40px 0;
          font-size: 30px;
        }

        a {
          width: 270px;
          height: 57px;
          margin-bottom: 10px;
          font-size: 16.5px;
        }
      }

      .recruit_content_small {
        .target {
          h3 {
            font-size: 28px;
          }
        }

        .notes {
          h3 {
            font-size: 28px;
          }

          p {
            font-size: 16px;
          }
        }
      }
    }
  }
}

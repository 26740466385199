.login {
  height: calc(100vh - 320px);
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  form {
    width: 470px;
    height: 470px;
    padding: 50px 40px;
    display: flex;
    flex-direction: column;
    text-align: center;

    h4 {
      margin: 0 128px 65px 128px;
      font-size: 45px;
      font-weight: 600;
    }

    input {
      height: 60px;
      padding: 0 13px;
      margin-bottom: 11px;
      font-size: 18px;
      border: 2px solid rgb(224, 224, 224);
      border-radius: 5px;
      outline: none;
    }

    input::placeholder {
      color: rgb(180, 180, 180);
      font-weight: 500;
      font-size: 18px;
      font-family: "Pretendard Variable", Pretendard, -apple-system,
        BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
        "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic",
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    }

    button {
      height: 60px;
      margin-top: 13px;
      background-color: #000080;
      opacity: 0.5;
      color: white;
      border: none;
      border-radius: 5px;
      font-size: 22px;
      font-weight: 500;
      font-family: "Pretendard Variable", Pretendard, -apple-system,
        BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
        "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic",
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    }

    button:hover {
      cursor: pointer;
    }

    .links {
      display: flex;
      justify-content: space-between;

      a {
        padding: 11px 1px;
        color: black;
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
}

@media all and (max-width: 1280px) {
  .login {
    form {
      // border: 1px solid red;
      width: 450px;
      height: 450px;
      padding: 48px 40px;
      padding-top: 50px;

      h4 {
        margin: 0 0 60px 0;
        font-size: 42px;
      }

      input {
        height: 58px;
        padding: 0 12px;
        margin-bottom: 10px;
        font-size: 17.8px;
      }

      input::placeholder {
        font-size: 17.8px;
      }

      button {
        height: 58px;
        margin-top: 12px;
        font-size: 21px;
      }

      .links {
        a {
          padding: 10px 1px;
          font-size: 18px;
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .login {
    form {
      width: 420px;
      height: 420px;
      padding: 42px 30px;

      h4 {
        margin: 0 0 53px 0;
        font-size: 40px;
      }

      input {
        height: 54px;
        padding: 0 10px;
        margin-bottom: 8px;
        font-size: 16.7px;
      }

      input::placeholder {
        font-size: 16.7px;
      }

      button {
        height: 54px;
        margin-top: 10px;
        font-size: 19px;
      }

      .links {
        a {
          padding: 9px 1px;
          font-size: 17px;
        }
      }
    }
  }
}

@media all and (max-width: 480px) {
  .login {
    form {
      // border: 1px solid red;
      width: 330px;
      height: 340px;
      padding: 30px 20px;

      h4 {
        margin: 0 0 40px 0;
        font-size: 29px;
      }

      input {
        height: 50px;
        padding: 0 10px;
        margin-bottom: 8px;
        font-size: 16.5px;
      }

      input::placeholder {
        font-size: 15.8px;
      }

      button {
        height: 52px;
        margin-top: 10px;
        font-size: 17.5px;
      }

      .links {
        a {
          padding: 9px 1px;
          font-size: 15.8px;
        }
      }
    }
  }
}
